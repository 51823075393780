import React, { useRef } from "react";
import BannerCard from "./BannerCard/BannerCard";
import BannerCarousel from "./BannerCarousel/BannerCarousel";
import useOnScreen from "../../../../utils/hooks/useOnScreen";
import { CONTENT_DISABLE_BANNER_OVERLAY, DAVINCI_PAGE_MERCHANT } from "../../../../utils/constants";
import DaVinciService from "../../../../services/davinciService";
import styles from "./BannerRow.scss";
import {
  BannerComponentDataRowSubTypeEnums,
  MAX_SHOW_LENGTH_CUSTOM_BANNER,
  MIN_SHOW_LENGTH_CUSTOM_BANNER,
  MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER
} from "./contants";
import Carousel from "@cosmos/carousel";
import useCheckMobileScreen from "../../../../utils/hooks/useCheckMobileScreen";

const BannerRow = ({ row, merchantDetail, index, hbAdultValue, isBot }) => {
  const { isMobile } = useCheckMobileScreen();
  const observerRef = useRef();

  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  const clickEventHandler = (bannerIndex, event) => {
    davinci[event](row, index + 1, bannerIndex);
  };
  const bannerTagEvents = bannerIndex => ({
    onView: prodIndex => davinci["viewCustomBannerProductTagEvent"](row, index + 1, bannerIndex, prodIndex),
    onClick: prodIndex => davinci["clickCustomBannerProductTagEvent"](row, index + 1, bannerIndex, prodIndex)
  });

  const disableBannerOverlay = merchantDetail?.tagList.includes(CONTENT_DISABLE_BANNER_OVERLAY);

  const handleRenderBannerByRowSubType = () => {
    switch (row.subType) {
      case BannerComponentDataRowSubTypeEnums.BANNER:
        useOnScreen(observerRef, () => davinci.viewBannerRowEvent(row, index + 1));
        return (
          <div ref={observerRef} className={styles.bannerRow} key={index}>
            {row?.data.length > 0 &&
              row?.data?.map((banner, index) => (
                <div key={banner.id}>
                  <BannerCard
                    hbAdultValue={hbAdultValue}
                    banner={banner}
                    background={row.background}
                    clickEventHandler={() => clickEventHandler(index, "clickBannerRowEvent")}
                    dataLength={row?.data?.length}
                    isBot={isBot}
                  />
                </div>
              ))}
          </div>
        );
      case BannerComponentDataRowSubTypeEnums.CUSTOM_BANNER:
        useOnScreen(observerRef, () => davinci.viewCustomBannerRowEvent(row, index + 1));
        return (
          <div ref={observerRef} key={index}>
            {!isMobile &&
              row.data.length <= MAX_SHOW_LENGTH_CUSTOM_BANNER &&
              row.data.length >= MIN_SHOW_LENGTH_CUSTOM_BANNER && (
                <div className={styles.bannerRow} style={{ maxWidth: 1200 }}>
                  {row?.data.length > 0 &&
                    row?.data?.map((banner, bannerIndex) => (
                      <div key={banner.id} style={{ width: "100%" }}>
                        <BannerCarousel
                          banner={banner}
                          size={row?.data.length}
                          bannerTagEvents={bannerTagEvents(bannerIndex)}
                          clickEventHandler={() => clickEventHandler(bannerIndex, "clickCustomBannerRowEvent")}
                          disableBannerOverlay={disableBannerOverlay}
                          isBot={isBot}
                        />
                      </div>
                    ))}
                </div>
              )}
            {!isMobile && row.data.length > MAX_SHOW_LENGTH_CUSTOM_BANNER && (
              <div className={styles.carouselRoot} style={{ maxWidth: 1200 }}>
                <Carousel>
                  {row?.data.length > 0 &&
                    row?.data?.map((banner, bannerIndex) => (
                      <div key={banner.id} className={styles.carouselContainer}>
                        <BannerCarousel
                          banner={banner}
                          size={row?.data.length}
                          bannerTagEvents={bannerTagEvents(bannerIndex)}
                          clickEventHandler={() => clickEventHandler(bannerIndex, "clickCustomBannerRowEvent")}
                          disableBannerOverlay={disableBannerOverlay}
                          isBot={isBot}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            )}
            {isMobile && row.data.length > MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER && (
              <div className={styles.carouselRoot}>
                <Carousel>
                  {row?.data.length > 0 &&
                    row?.data?.map((banner, bannerIndex) => (
                      <div key={banner.id} className={styles.carouselContainer}>
                        <BannerCarousel
                          banner={banner}
                          size={row?.data.length}
                          bannerTagEvents={bannerTagEvents(bannerIndex)}
                          clickEventHandler={() => clickEventHandler(bannerIndex, "clickCustomBannerRowEvent")}
                          disableBannerOverlay={disableBannerOverlay}
                          isBot={isBot}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            )}
            {isMobile && row.data.length === MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER && (
              <div className={styles.bannerRow}>
                {row?.data.length > 0 &&
                  row?.data?.map((banner, bannerIndex) => (
                    <div key={banner.id} style={{ width: "100%" }}>
                      <BannerCarousel
                        banner={banner}
                        size={row?.data.length}
                        bannerTagEvents={bannerTagEvents(bannerIndex)}
                        clickEventHandler={() => clickEventHandler(bannerIndex, "clickCustomBannerRowEvent")}
                        disableBannerOverlay={disableBannerOverlay}
                        isBot={isBot}
                      />
                    </div>
                  ))}
              </div>
            )}
            {!isMobile && row.data.length === MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER && (
              <div className={styles.bannerRow} style={{ maxWidth: 1200 }}>
                {row?.data.length > 0 &&
                  row?.data?.map((banner, bannerIndex) => (
                    <div key={banner.id} style={{ width: "100%" }}>
                      <BannerCarousel
                        banner={banner}
                        size={row?.data.length}
                        bannerTagEvents={bannerTagEvents(bannerIndex)}
                        clickEventHandler={() => clickEventHandler(bannerIndex, "clickCustomBannerRowEvent")}
                        disableBannerOverlay={disableBannerOverlay}
                        isBot={isBot}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
    }
  };

  return handleRenderBannerByRowSubType();
};

export default BannerRow;
